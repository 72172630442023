const generateIds = () => ({
  cc_number_container: {
    title: "Seleccionar identificación",
    class: "Selection",
    description: "Despliega la lista y selecciona tu tipo de identificación"
  },
  id_number_container: {
    title: "Número de identificación",
    class: "Identification",
    description: "Haz clic sobre el rectángulo e ingresa el número"
  },
  phone_number_container: {
    title: "Número de celular",
    class: "PhoneNumber",
    description:
      "Este número será utilizado en caso de que se presente alguna falla en la videollamada"
  },

  "authorizations-container": {
    title: "Autorizaciones médicas",
    class: "Att1",
    description:
      "Haz clic aquí para tramitar o consultar autorizaciones médicas"
  },
  "service_client-container": {
    title: "Servicio al cliente",
    class: "Att2",
    description: "Haz clic aquí para trámites administrativos de tu contrato"
  },

  "video-tutorial": {
    title: "Video de turnos",
    class: "Att",
    description: "Aquí puedes hacer seguimiento a tu puesto en fila"
  }

  // Aquí puedes hacer seguimiento a tu puesto en fila

  // microphone: {
  //   title: "Micrófono",
  //   class: "Mic",
  //   description: "Haz clic para activar o desactivar el audio"
  // },
  // video_camera: {
  //   title: "Cámara de video",
  //   class: "VideoCam",
  //   description: "Haz clic para activar o desactivar tu cámara"
  // },
  // finish_video_call: {
  //   title: "Colgar la videollamada",
  //   class: "EndVideoCall",
  //   description: "Haz clic, si deseas cancelar la videollamada"
  // },
  // add_files: {
  //   title: "Adjuntar archivos",
  //   class: "AddFiles",
  //   description:
  //     "Haz clic sobre el ícono si deseas adjuntar un archivo para enviar"
  // },
  // write_message: {
  //   title: "Escribe tu mensaje",
  //   class: "WriteMessage",
  //   description: "Haz clic sobre el texto para escribir un mensaje"
  // },
  // send_message: {
  //   title: "Enviar",
  //   class: "SendMessage",
  //   description: "Haz clic sobre el ícono para enviar tu mensaje o archivo"
  // }
});

export default generateIds;
